import Inspection from '@/models/Inspection'
import Paginator from '@/models/Paginator'
import { inspection as backend } from '@/api'
import { common as commonBackend } from '@/api'

let inspection = {

  namespaced: true,

  state: {
    inspection: new Inspection,
    inspections: new Paginator,
    upcomingInspections: [],
    failedChecks: [],
  },

  mutations: {
    setInspection: (state, payload) => state.inspection = payload,
    setInspections: (state, payload) => state.inspections = payload,
    setUpcomingInspections: (state, payload) => state.upcomingInspections = payload,
    setFailedChecks: (state, payload) => state.failedChecks = payload,
    clear: (state) => {
      state.inspection = new Inspection
      state.inspections = new Paginator
    },
    addInspection: (state, payload) => {
      state.inspections.data.push(payload)
      state.inspections.total++
    }
  },

  actions: {
    load: ({ commit }, inspectionId) => new Promise((resolve, reject) => {
      backend.load(inspectionId, ({ data }) => {
        commit('setInspection', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadInspections: ({ commit }, payload) => new Promise((resolve, reject) => {
      commonBackend.loadPath(payload, ({ data }) => {
        commit('setInspections', data)
        resolve()
      }, error => reject(error))
    }),
    loadFailedChecks: ({ commit }, payload) => new Promise((resolve, reject) => {
      commonBackend.loadPath(payload, ({ data }) => {
        commit('setFailedChecks', data)
        resolve(data)
      }, error => reject(error))
    }),
    deleteInspection: ({}, inspectionId) => new Promise((resolve, reject) => {
      backend.deleteInspection(inspectionId, ({}) => {
        resolve()
      }, error => reject(error))
    }),
    recover: ({}, inspectionId) => new Promise((resolve, reject) => {
      backend.recover(inspectionId, ({}) => {
        resolve()
      }, error => reject(error))
    }),
    getAssetUpcomingInspections: ({ commit }, assetId) => new Promise((resolve, reject) => {
      backend.getAssetUpcomingInspections(assetId, ({ data }) => {
        commit('setUpcomingInspections', data)
        resolve(data)
      }, error => reject(error))
    }),
  },

  getters: {
    inspection: state => state.inspection,
    inspections: state => state.inspections,
    upcomingInspections: state => state.upcomingInspections,
    failedChecks: state => state.failedChecks,
    defectiveChecksCount: state => state.inspection.answers.filter(answer => {
      return Boolean(answer.answer) === false
    }).length
  }

}

export default inspection
