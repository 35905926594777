import Document from '@/models/Document'
import Paginator from '@/models/Paginator'
import { documents as backend } from '@/api'
import { common as commonBackend } from '@/api'

export default {

  namespaced: true,

  state: {
    document: new Document,
    documents: new Paginator
  },

  mutations: {
    title: (state, payload) => state.document.title = payload,
    description: (state, payload) => state.document.description = payload,
    setFile: (state, payload) => state.document.file = payload,
    expiryDate: (state, payload) => state.document.expires_at = payload,
    clear: (state) =>  state.document = new Document,
    clearAll: (state) => {
      state.document = new Document
      state.documents = new Paginator
    },
    setDocument: (state, payload) => state.document = payload,
    setDocuments: (state, payload) => state.documents = payload,
  },

  actions: {
    uploadDocument: ({state}, path) => new Promise((resolve, reject) => {
      backend.uploadDocument({
        path,
        document: state.document
      }, () => {
        resolve()
      }, error => reject(error))
    }),
    replaceDocument: ({state}) => new Promise((resolve, reject) => {
      backend.replaceDocument(state.document, response => {
        resolve()
      }, error => reject(error))
    }),
    loadDocuments: ({commit}, payload) => new Promise((resolve, reject) => {
      commonBackend.loadPath(
        payload, 
        ({data}) => {
          commit('setDocuments', data)
          resolve(data)
        }, 
        error => reject(error)
      )
    }),
    deleteDocument: ({}, docId) => new Promise((resolve, reject) => {
      backend.deleteDocument(docId, ({data}) => {
        resolve()
      }, error => reject(error))
    }),
  },

  getters: {
    file: state => state.document,
    documents: state => state.documents,
  }

}