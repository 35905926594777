import Zone from '@/models/Zone'
import { common as commonBackend, zone as backend } from '@/api'

let zone = {

  namespaced: true,

  state: {
    zones: {
      data: []
    },
    zone: new Zone,
    overview: {
      risk: {},
      open_jobs: []
    }
  },

  mutations: {
    setZones: (state, payload) => state.zones = payload,
    setZone: (state, payload) => state.zone = payload,
    name: (state, payload) => state.zone.name = payload,
    size: (state, payload) => state.zone.size = payload,
    type: (state, payload) => state.zone.type_id = payload,
    setSite: (state, payload) => state.zone.site_id = payload,
    setOverview: (state, payload) => state.overview = payload,
    clear: (state) => {
      state.zones = {
        data: []
      },
        state.zone = new Zone
    },
    clearZone(state) {
      state.zone = new Zone
    }
  },

  actions: {
    loadZonesBySite: ({ commit }, payload) => new Promise((resolve, reject) => {
      commonBackend.loadPath(payload, ({ data }) => {
        commit('setZones', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadZone: ({ commit }, zoneId) => new Promise((resolve, reject) => {
      backend.loadZone(zoneId, ({ data }) => {
        commit('setZone', data)
        resolve(data)
      }, error => reject(error))
    }),
    createZone: ({ state }) => new Promise((resolve, reject) => {
      backend.createZone(
        state.zone,
        ({ data }) => resolve(data),
        error => reject(error)
      )
    }),
    saveZone: ({ state }) => new Promise((resolve, reject) => {
      backend.saveZone(
        state.zone,
        () => resolve(),
        error => reject(error)
      )
    }),
    deleteZone: ({ state }) => new Promise((resolve, reject) => {
      backend.deleteZone(
        state.zone.id,
        () => resolve(),
        error => reject(error)
      )
    }),
    loadOverview: ({ commit }, zoneId) => new Promise((resolve, reject) => {
      backend.loadOverview(
        zoneId,
        ({ data }) => {
          commit('setOverview', data)
          resolve()
        },
        error => reject(error)
      )
    }),
    transferZone: ({ commit }, payload) => new Promise((resolve, reject) => {
      backend.transferToSite(
        payload.zone_id,
        payload.to_site_id,
        ({ data }) => resolve(data),
        error => reject(error)
      )
    }),
  },

  getters: {
    zones: state => state.zones,
    zone: state => state.zone,
    overview: state => state.overview
  }

}

export default zone