import { sensor as backend } from '@/api'
import Sensor from '@/models/Sensor'

let sensor = {

  namespaced: true,

  state: {
    sensor: new Sensor,
    locations: [],
    readings: {},
  },

  mutations: {
    setSensor: (state, payload) => state.sensor = payload,
    setLocations: (state, payload) => state.locations = payload,
    setReadings: (state, payload) => state.readings = payload,
  },

  actions: {
    loadSensor: ({ commit }, sensorId) => new Promise((resolve, reject) => {
      backend.loadSensor(
        sensorId,
        ({ data }) => {
          commit('setSensor', data)
          resolve(data)
        },
        error => reject(error)
      )
    }),
    loadReadings: ({ commit }, payload) => new Promise((resolve, reject) => {
      backend.loadReadings(
        payload,
        ({ data }) => {
          commit('setReadings', data)
          resolve(data)
        },
        error => reject(error)
      )
    }),
    loadLocations: ({ commit }, payload) => new Promise((resolve, reject) => {
      backend.loadLocations(
        payload,
        ({ data }) => {
          commit('setLocations', data)
          resolve(data)
        },
        error => reject(error)
      )
    }),
    updateSensorPersonalName: ({ commit }, payload) => new Promise((resolve, reject) => {
      backend.updateSensorPersonalName(
        payload,
        response => resolve(response),
        error => reject(error)
      )
    }),
  },

  getters: {
    sensor: state => state.sensor,
    locations: state => state.locations,
    readings: state => state.readings,
  }

}

export default sensor