import { common as backend } from '@/api'
import { sortBy } from 'lodash'

export default {

  namespaced: true,

  state: {
    ticket_priorities: [
      { value: 1, label: 'Low' },
      { value: 2, label: 'Medium' },
      { value: 3, label: 'High' },
    ],
    ticket_types: [
      // { value: 1, label: 'Feature Request' },
      { value: 2, label: 'Support Request' },
      { value: 3, label: 'Other Request' },
    ],
    check_rules: [
      {
        value: 'not-required',
        label: 'Not required (optional)',
      },
      {
        value: 'when-defective',
        label: 'Required if failed',
      },
      {
        value: 'always-required',
        label: 'Always required',
      }
    ],
    industries: [],
    regions: [],
    countries: [],
    timezones: [],
    job_statuses: [
      { label: 'In Progress', value: 'in progress' },
      { label: 'Pending Signature', value: 'pending-signature'},
      { label: 'Open', value: 'open' },
      { label: 'Passed', value: 'passed' },
      { label: 'Failed', value: 'failed' },
      { label: 'Paused', value: 'paused' },
    ],
    company_sizes: [
      { label: '1-5', value: '1-5' },
      { label: '6-15', value: '6-15' },
      { label: '15-30', value: '15-30' },
      { label: '30-50', value: '30-50' },
      { label: '50-100', value: '50-100' },
      { label: '100-200', value: '100-200' },
      { label: '200-500', value: '200-500' },
      { label: '500-1000', value: '500-1000' },
      { label: '1000-2000', value: '1000-2000' },
      { label: '2000-5000', value: '2000-5000' },
      { label: '5000+', value: '5000+' },
    ]
  },

  mutations: {
    setIndustries: (state, payload) => state.industries = payload,
    setTimezones: (state, payload) => state.timezones = payload,
    setRegions: (state, payload) => state.regions = payload,
    setCountries: (state, payload) => state.countries = payload,
  },

  actions: {
    loadIndustries: ({ commit }) => new Promise((resolve, reject) => {
      backend.loadPath({ path: '/api/meta/industries' }, ({ data }) => {
        commit('setIndustries', data)
        resolve()
      }, reject)
    }),
    loadRegions: ({ commit }) => new Promise((resolve, reject) => {
      backend.loadPath({ path: '/api/meta/regions' }, ({ data }) => {
        commit('setRegions', data)
        resolve()
      }, reject)
    }),
    loadCountries: ({ commit }) => new Promise((resolve, reject) => {
      backend.loadPath({ path: '/api/meta/countries' }, ({ data }) => {
        commit('setCountries', data)
        resolve()
      }, reject)
    }),
    loadTimezones: ({ commit }) => new Promise((resolve, reject) => {
      backend.loadPath({ path: '/api/meta/timezones' }, ({ data }) => {
        commit('setTimezones', data)
        resolve()
      }, reject)
    })
  },

  getters: {
    checkRules: state => state.check_rules,
    ticketPriorities: state => state.ticket_priorities,
    ticketTypes: state => state.ticket_types,
    industries: state => state.industries,
    timezones: state => state.timezones,
    regions: state => state.regions,
    jobStatuses: state => state.job_statuses,
    companySizes: state => state.company_sizes,
    countries: state => sortBy(state.countries, 'name'),
    dialingCodes: ({}, getters) => sortBy(getters.countries.map(country => {
      return {
        value: country.alpha_2_code,
        label: country.alpha_2_code + ' +' + country.dialing_code
      }
    }), 'value'),
  }

}
