import {namingConventions as backend} from "@/api";

export default {
    namespaced: true,

    state: {
        names: null,
    },

    getters: {
        names: (state) => {

            return state.names
        }
    },

    mutations: {
        setNamingConventions: (state, payload) => {
            state.names = payload
        },
        updateNamingConvention: (state, payload) => {
            state.names[payload.label] = payload.value
        },
    },
    actions: {
        getNamingConventions: ({ commit }) => new Promise((resolve, reject) => {
            backend.getNamingConventions(response => {
                commit('setNamingConventions', response.data)
                resolve()
            }, reject)
        }),
        postNamingConventions: ({ commit }, payload) => new Promise((resolve, reject) => {
            const naming_conventions = []
            Object.entries(payload).forEach((entry) => {
                const [key, value] = entry
                naming_conventions.push({label: key, value: value})
            })
            backend.postNamingConventions(response => {
                commit('setNamingConventions', response.data)
                resolve()
            }, reject, { naming_conventions: naming_conventions})
        })
    },
}