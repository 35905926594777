import { checklist as backend } from '@/api'
import { common as commonBackend } from '@/api'
import {cloneDeep} from "lodash";
import Checklist from "@/models/Checklist";
import {mergeChecksWithChangeTypes} from "@/utils/checklists/checklist-diff";


let checklist = {

  namespaced: true,

  state: {
    checklists: {
      data: []
    },
    checklistList: [],
    checklist: new Checklist(),
    currChecklist: new Checklist(),
    checklistVersions: []
  },

  mutations: {
    setChecklists: (state, payload) => state.checklists = payload,
    setChecklistVersions: (state, payload) => state.checklistVersions = payload,
    setChecklistsList: (state, payload) => state.checklistList = payload,
    setChecklist: (state, payload) => {
      state.checklist = cloneDeep(payload)
      state.currChecklist = cloneDeep(payload);
    },
    setChecks: (state, payload) => state.checklist.checks = payload,
    name: (state, payload) => state.checklist.name = payload,
    frequency: (state, payload) => {
      state.checklist.frequency = payload
      if(state.checklist.frequency < 1) {
        state.checklist.sets_next_inspection = false
      }
    },
    toggleSignatureRequirement: (state) => state.checklist.requires_signature = !state.checklist.requires_signature,
    toggleApproverSignatureRequirement: (state) => state.checklist.requires_approver_signature = !state.checklist.requires_approver_signature,
    toggleInspectionReportImages: (state) => state.checklist.inspection_report_images_inline = !state.checklist.inspection_report_images_inline,
    toggleCustomReport: (state) => state.checklist.use_custom_report = !state.checklist.use_custom_report,
    passPercentage: (state, payload) => state.checklist.pass_percentage = payload,
    imageRule: (state, payload) => state.checklist.image_rule = payload,
    imageCount: (state, payload) => state.checklist.required_image_count = payload,
    toggleUpdatesNextInspection: (state) => state.checklist.sets_next_inspection = !state.checklist.sets_next_inspection,
    addCheck: (state, check) => state.checklist.checks.push(check),
    removeCheck: (state, index) => state.checklist.checks.splice(index, 1),
    clear: (state) => {
      state.checklists = {
        data: []
      }
      state.checklistList = []
      state.checklist = new Checklist
    },
    updateAssetTypes: (state, payload) => state.checklist.asset_types = payload,
  },

  actions: {
    loadChecklists: ({commit}, path) => new Promise((resolve, reject) => {
      commonBackend.loadPath(path, response => {
        commit('setChecklists', response.data)
        resolve()
      }, error => reject(error))
    }),
    loadChecklistList: ({commit}) => new Promise((resolve, reject) => {
      backend.loadChecklistList(response => {
        commit('setChecklistsList', response.data)
        resolve()
      }, error => reject(error))
    }),
    loadChecklistSelectList: ({commit}) => new Promise((resolve, reject) => {
      backend.loadChecklistSelectList(response => {
        commit('setChecklistsList', response.data)
        resolve()
      }, error => reject(error))
    }),
    searchChecklistSelectList: ({ commit }, search) => new Promise((resolve, reject) => {
      backend.searchChecklistSelectList(search, ({ data }) => {
        commit('setChecklistsList', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadChecklist: ({commit}, checklistId) => new Promise((resolve, reject) => {
      backend.loadChecklist(checklistId, response => {
        commit('setChecklist', response.data)
        resolve()
      }, error => reject(error))
    }),
    loadChecklistVersions: ({commit}, checklistId) => new Promise((resolve, reject) => {
      backend.loadChecklistVersions(checklistId, response => {
        commit('setChecklistVersions', response.data)
        resolve()
      }, error => reject(error))
    }),
    store: ({state}) => new Promise((resolve, reject) => {
      let checklist = {...state.checklist}
      checklist.checks = checklist.checks.map(check => check.id)
      backend.store(checklist, response => {
        resolve(response.data)
      }, error => reject(error))
    }),
    save: ({state, commit}) => new Promise((resolve, reject) => {
      let checklist = {...state.checklist}
      checklist.checks = checklist.checks.map(check => check.id)
      backend.save(checklist, response => {
        commit('setChecklist', response.data)
        resolve()
      }, error => reject(error))
    }),
    deleteChecklist: ({state}) => new Promise((resolve, reject) => {
      backend.deleteChecklist(state.checklist.id, response => {
        resolve(response.data)
      }, error => reject(error))
    }),
    duplicate: ({state}, detach) => new Promise((resolve, reject) => {
      backend.duplicate({checklistId: state.checklist.id, detach: detach}, response => {
        resolve(response.data)
      }, error => reject(error))
    }),
    syncAssetTypes: ({state}) => new Promise((resolve, reject) => {
      backend.syncAssetTypes(state.checklist, response => {
        resolve(response.data)
      }, error => reject(error))
    }),
  },

  getters: {
    checklists: state => state.checklists,
    checklist: state => state.checklist,
    currChecklist: state => state.currChecklist,
    checklistVersions: state => state.checklistVersions.sort((a, b) => a.version < b.version ? 1 : -1),
    comparedChecklistVersions: (state) => {
      const result = [];

      // Loop through each version, comparing each with its previous version
      for (let i = 1; i < state.checklistVersions.length; i++) {
        const previousChecklist = state.checklistVersions[i - 1];
        const currentChecklist = state.checklistVersions[i];

        // Merge the checks with the change type for this version
        const mergedChecks = mergeChecksWithChangeTypes(
            previousChecklist.checks,
            currentChecklist.checks
        );

        // Add current checklist with annotated checks to result
        result.push({ ...currentChecklist, check_changes: mergedChecks });
      }

      return result.sort((a, b) => a.version < b.version ? 1 : -1);
    },
    checklistList: state => state.checklistList,
  }

}

export default checklist
