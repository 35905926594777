import backend from "@/api/userPersonas"
import UserPersona from "@/models/UserPersona";
import common from "@/api/common";

let userPersonas = {

    namespaced: true,

    state: {
        personas: {
            data: [],
        },
        persona: new UserPersona(),
        selectedSites: [],
        availableSites: {
            data: []
        },
        availableTechnicians: {
            data: []
        },
        selectedTechnicians: [],
        canAssignPersona: {
            can_assign: false,
            open_jobs: null,
            unknown_sites: []
        },
        transactions: {
            data: [],
        },
        personaList: [],
    },

    mutations: {
        setPersonaList: (state, payload) => state.personaList = payload,
        setTransactions: (state, payload) => state.transactions = payload,
        setPersonas: (state, payload) => state.personas = payload,
        clearUserPersona: (state) => {
            state.persona = new UserPersona()
            state.selectedSites = []
            state.availableSites = {data:[]}
            state.selectedTechnicians = []
            state.availableTechnicians = {data: []}
        },
        name: (state, payload) => state.persona.name = payload,
        sites: (state, payload) => state.persona.sites = payload,
        setAvailableSites: (state, payload) => state.availableSites = payload,
        setAvailableTechnicians: (state, payload) => state.availableTechnicians = payload,
        removeSite: (state, payload) => {{
            let index = state.selectedSites.indexOf(payload)
            state.selectedSites.splice(index, 1)
        }},
        addSitesToSelectedSites: (state, payload) => {
            state.selectedSites = payload
        },
        removeTechnician: (state, payload) => {
            let index = state.selectedTechnicians.indexOf(payload)
            state.selectedTechnicians.splice(index, 1)
        },
        setPersona: (state, payload) => {
            state.persona = payload
            state.selectedSites = state.persona.sites.map((site) => {
                return {id: site.site_id, name: site.name, client: site.client}
            })
            state.selectedTechnicians = state.persona.technicians?.map((technician) => {
                return {id: technician.id, full_name: technician.full_name, email: technician.email, created_at: technician.created_at}
            })
        },
        setCanAssignPersona: (state, payload) => state.canAssignPersona = payload,
    },

    actions: {
        resubmit: ({}, transactionId) => new Promise((resolve, reject) => {
            backend.resubmit(transactionId, ({data}) => {
                resolve(data)
            }, error => reject(error))
        }),
        loadTransactions: ({commit}, path) => new Promise((resolve, reject) => {
            common.loadPath(path, ({data}) => {
                commit("setTransactions", data)
                resolve(data)
            }, error => reject(error))
        }),
        loadPersonas: ({commit}, path) => new Promise((resolve, reject) => {
            common.loadPath(path, ({data}) => {
                commit('setPersonas', data)
                resolve(data)
            }, error => reject(error))
        }),
        availableSites: ({commit}, payload) => new Promise((resolve, reject) => {
            backend.loadAvailableSites(payload, ({data}) => {
                commit('setAvailableSites', data)
                resolve(data)
            }, error => reject(error))
        }),
        addAllSites: ({commit}) => new Promise((resolve, reject) => {
            backend.loadAllSites(({data}) => {
                commit('addSitesToSelectedSites', data)
                resolve()
            }, error => reject(error))
        }),
        availableTechnicians: ({commit}, payload) => new Promise((resolve, reject) => {
            backend.loadAvailableTechnicians(payload, ({data}) => {
                commit('setAvailableTechnicians', data)
                resolve(data)
            }, error => reject(error))
        }),
        store: ({commit}, payload) => new Promise((resolve, reject) => {
            backend.storeUserPersona(payload, ({data}) => {
                commit("clearUserPersona")
                resolve(data)
            }, error => reject(error))
        }),
        update: ({commit}, payload) => new Promise((resolve, reject) => {
            backend.updateUserPersona(payload, ({data}) => {
                commit("clearUserPersona")
                resolve(data)
            }, error => reject(error))
        }),
        loadPersona: ({commit}, payload) => new Promise((resolve, reject) => {
            backend.getUserPersona(payload, ({data}) => {
                commit("setPersona", data)
                resolve(data)
            }, error => reject(error))
        }),
        validateCanAssignPersona: ({commit}, payload) => new Promise((resolve, reject) => {
            backend.canAssignPersona(payload, ({data}) => {
                commit("setCanAssignPersona", data)
                resolve(data)
            }, error => reject(error))
        }),
        attachPersona: ({}, payload) => new Promise((resolve, reject) => {
            backend.assignPersona(payload, ({data}) => {
                resolve(data)
            }, error => reject(error))
        }),
        searchPersonas: ({ commit }, search) => new Promise((resolve, reject) => {
            backend.searchPersonas(search, ({ data }) => {
                commit('setPersonas', data)
                resolve(data)
            }, error => reject(error))
        }),
        detachPersona: ({}, user) => new Promise((resolve, reject) => {
            backend.detachPersona(user, ({data}) => {
                resolve(data)
            }, error => reject(error))
        }),
        removePersona: ({}, personaId) => new Promise((resolve, reject) => {
            backend.deletePersona(personaId, ({data}) => {
                resolve(data)
            }, error => reject(error))
        }),
    },

    getters: {
        personas: state => state.personas,
        persona: state => state.persona,
        availableSites: state => state.availableSites,
        selectedSites: state => state.selectedSites,
        canAssignPersona: state => state.canAssignPersona,
        selectedTechnicians: state => state.selectedTechnicians,
        availableTechnicians: state => state.availableTechnicians,
        transactions: state => state.transactions,
    },
}

export default userPersonas