import Barcode from '@/models/Barcode'
import { barcode as backend } from '@/api'
import { common as commonBackend } from '@/api'

let barcode = {

  namespaced: true,

  state: {
    barcodes: {
      data: []
    },
    batch_numbers: [],
    barcode: new Barcode
  },

  mutations: {
    setBarcodes: (state, payload) => state.barcodes = payload,
    setBarcode: (state, payload) => state.barcode = payload,
    setBatches: (state, payload) => state.batch_numbers = payload,
    clear: (state) => {
      state.barcodes = {
        data: []
      }
      state.barcode = new barcode
    }
  },

  actions: {
    loadBarcodes: ({commit}, payload) => new Promise((resolve, reject) => {
      commonBackend.loadPath(payload, ({data}) => {
        commit('setBarcodes', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadBatchNumbers: ({commit}) => new Promise((resolve, reject) => {
      backend.loadBatchNumbers(({data}) => {
        commit('setBatches', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadBarcode: ({commit}, barcodeId) => new Promise((resolve, reject) => {
      backend.loadBarcode(barcodeId, ({data}) => {
        commit('setBarcode', data)
        resolve(data)
      }, error => reject(error))
    }),
    requestCodes: ({}, payload) => new Promise((resolve, reject) => {
      backend.requestCodes(payload, ({data}) => {
        resolve(data)
      }, error => reject(error))
    })
  },

  getters: {
    barcodes: state => state.barcodes,
    barcode: state => state.barcode,
    batch_numbers: state => state.batch_numbers,
  }

}

export default barcode