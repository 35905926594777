import {dynamicStatus as backend} from '@/api'
import DynamicStatusOption from "@/models/DynamicStatusOption";
import DynamicStatus from "@/models/DynamicStatus";

let dynamicStatus = {

    namespaced: true,

    state: {
        dynamicStatus: new DynamicStatus,
        dynamicStatusOption: new DynamicStatusOption,
        dynamicStatuses: [],
        dynamicStatusOptions: [],
    },

    mutations: {
        setDynamicStatus: (state, payload) => state.dynamicStatus = payload,
        setDynamicStatuses: (state, payload) => state.dynamicStatuses = payload,
        setDynamicStatusOptions: (state, payload) => {
            let status = state.dynamicStatuses.find(status => status.id === payload)
            state.dynamicStatusOptions = status.options ?? []
        },
        clearDynamicStatusOptions: (state) => state.dynamicStatusOptions = [],
        disableEditing: (state, payload) => state.dynamicStatuses.forEach(status => {
            if (status.id !== payload) {
                status.editable = false
            }
        }),
        enableEditing: (state) => state.dynamicStatuses.forEach(status => {
            status.editable = true
        })
    },

    actions: {
        storeDynamicStatus: ({}, payload) => new Promise(
            (resolve, reject) => {
                backend.storeDynamicStatus(payload, ({data}) => {
                    resolve(data)
                }, error => reject(error))
            }
        ),
        selectDynamicStatusOption: ({}, payload) => new Promise(
            (resolve, reject) => {
                backend.selectDynamicStatusOption(payload, ({data}) => {
                    resolve(data)
                }, error => reject(error))
            }
        ),
        updateDynamicStatus: ({}, payload) => new Promise(
            (resolve, reject) => {
                backend.updateDynamicStatus(payload, ({data}) => {
                    resolve(data)
                }, error => reject(error))
            }
        ),
        deleteDynamicStatus: ({commit}, statusId) => new Promise(
            (resolve, reject) => {
                backend.deleteDynamicStatus(statusId, ({data}) => {
                    resolve(data)
                }, error => reject(error))
            }
        ),
        deleteDynamicStatusOption: ({commit}, optionId) => new Promise(
            (resolve, reject) => {
                backend.deleteDynamicStatusOption(optionId, ({data}) => {
                    resolve(data)
                }, error => reject(error))
            }
        ),
        loadDynamicStatuses: ({commit}, assetTypeId) => new Promise((resolve, reject) => {
                backend.loadDynamicStatuses(assetTypeId, ({data}) => {
                    commit('setDynamicStatuses', data)
                    resolve(data)
                }, error => reject(error))
            }
        ),
    },

    getters: {
        dynamicStatus: state => state.dynamicStatus,
        dynamicStatuses: state => state.dynamicStatuses,
        dynamicStatusOption: state => state.dynamicStatusOption,
        dynamicStatusOptions: state => state.dynamicStatusOptions,
    }

}

export default dynamicStatus
