import { role as backend } from '@/api' 

let role = {
  
  namespaced: true,

  state: {
    roleList: []
  },

  mutations: {
    setRoleList: (state, payload) => state.roleList = payload
  },

  actions: {
    loadRoleList: ({commit}) => new Promise((resolve, reject) => {
      backend.loadRoleList(response => {
        commit('setRoleList', response.data)
        resolve(response.data)
      }, error => reject(error))
    })
  },

  getters: {
    roleList: state => state.roleList
  }

}

export default role