import { checkPredefinedComments as backend } from '@/api'

let checkPredefinedComments = {
    namespaced: true,

    state: {
        checkPredefinedComments: [],
        checkPredefinedCommentsToDestroy: [],
        check: null
    },

    mutations: {
        setCheck: (state, payload) => state.check = payload,
        setCheckPredefinedComments: (state, payload) => state.checkPredefinedComments = payload,
        addCheckPredefinedComment: (state, payload) => {
            state.checkPredefinedComments.push(payload)
        },
        updateCheckPredefinedComment: (state, {payload, index}) => {
            const checkPredefinedComment = state.checkPredefinedComments[index]
            checkPredefinedComment.check_id = payload.check_id
            checkPredefinedComment.status = payload.status
            checkPredefinedComment.text = payload.text
        },
        updateAllCheckPredefinedComments: (state) => {
            state.checkPredefinedComments.forEach(function (checkPredefinedComment, index) {
                checkPredefinedComment.check_id = state.check.id
            })
        },
        destroyCheckPredefinedComment: (state, {index}) => {
            const checkPredefinedComment = state.checkPredefinedComments[index]
            if (checkPredefinedComment.id !== null) {
                state.checkPredefinedCommentsToDestroy.push(checkPredefinedComment.id)
            }
            state.checkPredefinedComments.splice(index, 1)
        },
        destroyAllCheckPredefinedComments: (state) => {
            state.checkPredefinedComments.forEach(function (checkPredefinedComment, index) {
                if (checkPredefinedComment.id !== null) {
                    state.checkPredefinedCommentsToDestroy.push(checkPredefinedComment.id)
                }
            })
            state.checkPredefinedComments = []
        },
        clearCheckPredefinedCommentsToDestroy: (state) => state.checkPredefinedCommentsToDestroy = [],
        clear: (state) => {
            state.checkPredefinedComments = []
            state.checkPredefinedCommentsToDestroy = []
            state.check = null
        },
    },

    actions: {
        show: ({state, commit}) => new Promise((resolve, reject) => {
            if (state.check.id) {
                backend.show(state.check.id, response => {
                    commit('setCheckPredefinedComments', response.data)
                    resolve()
                }, error => reject(error))
            } else {
                resolve()
            }
        }),
        store: ({state, commit}) => new Promise((resolve, reject) => {
            const checkPredefinedCommentsToStore = state.checkPredefinedComments.filter(checkPredefinedComment => checkPredefinedComment.id === null && checkPredefinedComment.text)
            if (checkPredefinedCommentsToStore.length === 0) {
                resolve()
            } else {
                backend.store(checkPredefinedCommentsToStore, response => {
                    commit('setCheckPredefinedComments', response.data)
                    resolve()
                }, error => reject(error))
            }
        }),
        update: ({state, commit}) => new Promise((resolve, reject) => {
            const checkPredefinedCommentsToUpdate = state.checkPredefinedComments.filter(checkPredefinedComment => checkPredefinedComment.id !== null && checkPredefinedComment.text)
            if (checkPredefinedCommentsToUpdate.length === 0) {
                resolve()
            } else {
                backend.update(checkPredefinedCommentsToUpdate, response => {
                    resolve()
                }, error => reject(error))
            }
        }),
        destroy: ({state, commit}) => new Promise((resolve, reject) => {
            if (state.checkPredefinedCommentsToDestroy.length === 0) {
                resolve()
            } else {
                backend.destroy({'ids': state.checkPredefinedCommentsToDestroy}, response => {
                    commit('clearCheckPredefinedCommentsToDestroy')
                    resolve()
                }, error => reject(error))
            }
        })
    },

    getters: {
        checkPredefinedComments: state => state.checkPredefinedComments
    }
}

export default checkPredefinedComments