import User from '@/models/User'
import { user as backend } from '@/api'
import { common as commonBackend } from '@/api'
import UserProfile from '@/models/UserProfile'
import UserStat from '@/models/UserStat'
import Paginator from '@/models/Paginator'
import findIndex from 'lodash/findIndex'
import {commit} from "lodash/seq";

let user = {

  namespaced: true,

  state: {
    users: {
      data: []
    },
    jobs: {
      data: []
    },
    userList: [],
    adminList: [],
    user: new User,
    latestInspections: [],
    stats: new UserStat,
    documents: new Paginator
  },

  mutations: {
    setJobs: (state, payload) => state.jobs = payload,
    setUsers: (state, payload) => state.users = payload,
    setDocuments: (state, payload) => state.documents = payload,
    setUser: (state, payload) => {
      state.user = payload
      if (!state.user.profile) {
        state.user.profile = new UserProfile
      }
    },
    setUserList: (state, payload) => state.userList = payload,
    setAdminList: (state, payload) => state.adminList = payload,
    fullName: (state, payload) => state.user.full_name = payload,
    name: (state, payload) => state.user.name = payload,
    lastName: (state, payload) => state.user.last_name = payload,
    email: (state, payload) => state.user.email = payload,
    password: (state, payload) => state.user.password = payload,
    passwordConfirmation: (state, payload) => state.user.password_confirmation = payload,
    mobile: (state, payload) => state.user.profile.mobile = payload,
    dialingCode: (state, payload) => state.user.profile.dialing_code = payload,
    position: (state, payload) => state.user.profile.position = payload,
    roles: (state, payload) => state.user.roles = payload,
    clientSites: (state, payload) => state.user.client_sites = payload,
    addUser: (state, payload) => state.users.data.push(payload),
    toggleActive: (state) => state.user.active = !state.user.active,
    profilePic: (state, payload) => state.user.profile.picture = payload,
    profilePicUrl: (state, payload) => state.user.profile.picture_url = payload,
    updateTimezone: (state, timezone) => state.user.timezone = timezone,
    updateEmployeeNumber: (state, number) => state.user.profile.employee_number = number,
    updateCertificationNumber: (state, number) => state.user.profile.certification = number,
    updateIdNumber: (state, number) => state.user.profile.id_number = number,
    sites: (state, payload) => state.user.sites = payload,
    locked: (state, payload) => state.user.is_locked = payload,
    addClientSitesToUserSites: (state, payload) => {
      payload.map(site => {
        const index = +state.user.sites.map(site => site.id).indexOf(site.id)
        if(index === -1){
          state.user.sites.push(site)
        }
      })
    },
    removeClientSitesFromUserSites: (state, payload) => {
      payload.map(site => {
        const index = +state.user.sites.map(site => site.id).indexOf(site.id)
        if(index > -1){
          state.user.sites.splice(index, 1)
        }
      })
    },
    addClientSitesToClientUserSites: (state, payload) => {
      payload.map(site => {
        const index = +state.user.client_sites.map(site => site.id).indexOf(site.id)
        if(index === -1){
          state.user.client_sites.push({id: site.id})
        }
      })
    },
    removeClientSitesFromClientUserSites: (state, payload) => {
      payload.map(site => {
        const index = +state.user.client_sites.map(site => site.id).indexOf(site.id)
        if(index > -1){
          state.user.client_sites.splice(index, 1)
        }
      })
    },
    toggleSite: (state, site) => {
      let index = findIndex(state.user.sites, { id: site.id })
      if (index >= 0) {
        state.user.sites.splice(index, 1)
        return
      }
      state.user.sites.push(site)
    },
    toggleClientSite: (state, site) => {
      let index = findIndex(state.user.client_sites, { id: site.id })
      if (index >= 0) {
        state.user.client_sites.splice(index, 1)
        return
      }
      state.user.client_sites.push(site)
    },
    clearSites: (state) => state.user.sites = [],
    setStats: (state, payload) => {
      if (payload) state.stats = payload
    },
    setLatestInspections: (state, payload) => state.latestInspections = payload,
    clear: (state) => {
      state.user = new User
    },
    clearAll: (state) => {
      state.users = {
        data: []
      }
      state.user = new User
    },
    toggleSubscription: (state, payload) => {
      let index = state.user.notification_subscriptions.indexOf(payload)

      if (index > 0) {
        state.user.notification_subscriptions.splice(index, 1)
        return
      }
      state.user.notification_subscriptions.push(payload)
    },
    allowAbility: (state, ability) => state.user.abilities.push(ability),
    removeAbility: (state, ability) => {
      state.user.abilities.splice(findIndex(state.user.abilities, abil => abil.id === ability.id), 1)
    },
    setAbilities: (state, abilities) => state.user.abilities = abilities,
  },

  actions: {
    loadJobs: ({ commit }, path) => new Promise((resolve, reject) => {
      commonBackend.loadPath(path, ({ data }) => {
        commit('setJobs', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadUsers: ({ commit }, path) => new Promise((resolve, reject) => {
      commonBackend.loadPath(path, ({ data }) => {
        commit('setUsers', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadUser: ({ commit }, userId) => new Promise((resolve, reject) => {
      backend.loadUser(userId, ({ data }) => {
        console.log(data)
        commit('setUser', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadTechnicianList: ({ commit }) => new Promise((resolve, reject) => {
      backend.loadTechnicianList(({ data }) => {
        commit('setUserList', data)
        resolve(data)
      }, error => reject(error))
    }),
    searchTechnician: ({ commit }, search) => new Promise((resolve, reject) => {
      backend.searchTechnician(search, ({ data }) => {
        commit('setUserList', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadTechnicianSelectList: ({ commit }) => new Promise((resolve, reject) => {
      backend.loadTechnicianSelectList(({ data }) => {
        commit('setUserList', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadUserSiteIds: ({ commit }, userId) => new Promise((resolve, reject) => {
      backend.loadUserSiteIds(userId, ({ data }) => {
        resolve(data)
      }, error => reject(error))
    }),
    loadAdminSelectList: ({ commit }) => new Promise((resolve, reject) => {
      backend.loadAdminSelectList(({ data }) => {
        commit('setAdminList', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadUserList: ({ commit }) => new Promise((resolve, reject) => {
      backend.loadUserList(({ data }) => {
        commit('setUserList', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadActiveUserList: ({ commit }, query = {}) => new Promise((resolve, reject) => {
      backend.loadActiveUserList(query, ({ data }) => {
        commit('setUserList', data)
        resolve(data)
      }, error => reject(error))
    }),
    storeTechnician: ({ commit, state }) => new Promise((resolve, reject) => {
      backend.storeTechnician(state.user, ({ data }) => {
        commit('addUser', data)
        resolve(data)
      }, error => reject(error))
    }),
    storeAdmin: ({ commit, state }) => new Promise((resolve, reject) => {
      backend.storeAdmin(state.user, ({ data }) => {
        commit('addUser', data)
        resolve(data)
      }, error => reject(error))
    }),
    storeClientUser: ({ state }, clientId) => new Promise((resolve, reject) => {
      backend.storeClientUser({ user: state.user, clientId }, ({ data }) => {
        resolve(data.password)
      }, error => reject(error))
    }),
    save: ({ state }) => new Promise((resolve, reject) => {
      backend.save(state.user, ({ data }) => {
        resolve(data)
      }, error => reject(error))
    }),
    updateClientUser: ({ state }, clientId) => new Promise((resolve, reject) => {
      backend.updateClientUser({ user: state.user, clientId }, ({ data }) => {
        resolve(data)
      }, error => reject(error))
    }),
    uploadProfilePic: ({ commit }, payload) => new Promise((resolve, reject) => {
      backend.uploadProfilePic(payload, ({ data }) => {
        commit('profilePicUrl', data)
        resolve(data)
      }, error => reject(error))
    }),
    resetPassword: ({ state }, reset) => new Promise((resolve, reject) => {
      backend.resetPassword({
        userId: state.user.id,
        reset
      }, ({ }) => resolve(), error => reject(error))
    }),
    unlockUser: ({commit}, { userId }) => new Promise((resolve, reject) => {
      backend.unlockUser({
        userId: userId,
      }, () => {
        commit('locked', false);
        return resolve()
      },
          error => reject(error)
      )
    }),
    disableMFA: ({state}, { userId }) => new Promise((resolve, reject) => {
      backend.disableMFA({
        userId: userId,
      }, ({ }) => resolve(), error => reject(error))
    }),
    loadStats: ({ commit }, userId) => new Promise((resolve, reject) => {
      backend.loadStats(userId, ({ data }) => {
        commit('setStats', data)
        resolve()
      }, error => reject(error))
    }),
    loadLatestLocations: ({ commit }, userId) => new Promise((resolve, reject) => {
      backend.loadLatestLocations(userId, ({ data }) => {
        commit('setLatestInspections', data)
        resolve()
      }, error => reject(error))
    }),
    deactivate: ({ }, userId) => new Promise((resolve, reject) => {
      backend.deactivate(userId, ({ data }) => {
        resolve()
      }, error => reject(error))
    }),
    activate: ({ }, userId) => new Promise((resolve, reject) => {
      backend.activate(userId, ({ data }) => {
        resolve()
      }, error => reject(error))
    }),
    unsubscribeFromChannel: ({ state }, channel) => new Promise((resolve, reject) => {
      backend.unsubscribeFromChannel({
        user: state.user.id,
        channel
      }, () => {
        resolve()
      }, reject)
    }),
    subscribeToChannel: ({ state }, channel) => new Promise((resolve, reject) => {
      backend.subscribeToChannel({
        user: state.user.id,
        channel
      }, response => {
        resolve()
      }, reject)
    }),
    changeEmail: ({ state }, emailPayload) => new Promise((resolve, reject) => {
      backend.changeEmail({
        userId: state.user.id,
        email: emailPayload
      }, () => {
        resolve()
      }, error => reject(error))
    }),
    upgradeToAdmin: ({ state }) => new Promise((resolve, reject) => {
      backend.upgradeToAdmin({
        userId: state.user.id,
      }, () => {
        resolve()
      }, error => reject(error))
    }),
    downgradeToTechnician: ({ state }) => new Promise((resolve, reject) => {
      backend.downgradeToTechnician({
        userId: state.user.id,
      }, () => {
        resolve()
      }, error => reject(error))
    }),
    syncSites: ({ state }) => new Promise((resolve, reject) => {
      backend.syncSites(state.user.id, {
        sites: state.user.sites.map(site => site.id)
      }, resolve, reject)
    }),
    selectAllSites: ({ commit }, userId) => new Promise((resolve, reject) => {
      backend.selectAllSites(userId, ({ data }) => {
        commit('sites', data)
        resolve()
      }, error => reject(error))
    })
  },

  getters: {
    users: state => state.users,
    documents: state => state.documents,
    userList: state => state.userList,
    adminList: state => state.adminList,
    user: state => state.user,
    jobs: state => state.jobs,
    isTechnician: state => {
      return state.user.roles.filter(role => role.name === 'technician').length > 0
    },
    isAdmin: state => {
      return state.user.roles.filter(role => role.name === 'admin').length > 0
    },
    stats: state => state.stats,
    latestInspections: state => state.latestInspections,
    abilities: state => state.user.abilities
  }

}

export default user
