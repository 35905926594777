import AccountEnquiry from '@/models/AccountEnquiry'
import backend from '@/api/auth'

export default {

  namespaced: true,

  state: {
    enquiry: new AccountEnquiry
  },

  mutations: {
    fullName: (state, payload) => state.enquiry.full_name = payload,
    email: (state, payload) => state.enquiry.email = payload,
    companyName: (state, payload) => state.enquiry.company_name = payload,
    companySize: (state, payload) => state.enquiry.company_size = payload,
    country: (state, payload) => state.enquiry.country_id = payload,
    industry: (state, payload) => state.enquiry.industry_id = payload,
    industryOther: (state, payload) => state.enquiry.industry_other = payload,
    companyBio: (state, payload) => state.enquiry.company_bio = payload,
    dialingCode: (state, payload) => state.enquiry.dialing_code = payload,
    mobile: (state, payload) => state.enquiry.mobile = payload,
    clear: (state) => {
      state.enquiry = new AccountEnquiry
    }
  },

  actions: {
    submitEnquiry: ({state}) => new Promise((resolve, reject) => {
      backend.signupEnquiry(state.enquiry, () => {
        resolve()
      }, error => reject(error))
    })
  },

  getters: {
    enquiry: state => state.enquiry
  }

}