import { subscription as backend } from '@/api'
import { common as commonBackend } from '@/api'
import Paginator from '@/models/Paginator'

export default {

  namespaced: true,

  state: {
    subscription: {
      invoices: [],
      payments: []
    },
    invoices: new Paginator
  },

  mutations: {
    setSubscription: (state, payload) => state.subscription = payload,
    setInvoices: (state, payload) => state.invoices = payload
  },

  actions: {
    loadSubscription: ({commit}, companyId) => new Promise((resolve, reject) => {
      backend.loadSubscription(companyId, response => {
        commit('setSubscription', response.data)
        resolve()
      }, error => reject(error))
    }),
    loadInvoices: ({commit}, path) => new Promise((resolve, reject) => {
      commonBackend.loadPath(path, response => {
        commit('setInvoices', response.data)
        resolve()
      }, error => reject(error))
    }),
  },

  getters: {
    subscription: state => state.subscription,
    invoices: state => state.invoices,
  }

}