import AssetGroup from "@/models/AssetGroup"
import Paginator from "@/models/Paginator"
import { assetGroup as backend } from '@/api'

export default {

  namespaced: true,

  state: {
    assetGroups: new Paginator,
    assetGroup: new AssetGroup,
    assetGroupList: [],
  },

  mutations: {
    setAssetGroups: (state, payload) => state.assetGroups = payload,
    setAssetGroup: (state, payload) => state.assetGroup = payload,
    setAssetGroupList: (state, payload) => state.assetGroupList = payload,
    addType: (state, payload) => state.assetGroups.data.push(payload),
    description: (state, payload) => state.assetGroup.description = payload,
    risk: (state, payload) => state.assetGroup.risk_id = payload,
    clearType: (state) => state.assetGroup = new AssetGroup,
    zoneAssetTypes: (state, payload) => state.assetGroup.asset_types = payload,
  },

  actions: {
    loadAssetGroups: ({commit}, path) => new Promise((resolve, reject) => {
      backend.loadAssetGroups(path, ({data}) => {
        commit('setAssetGroups', data)
        resolve(data)
      }, error => reject(error))
    }), 
    loadAssetGroupList: ({commit}) => new Promise((resolve, reject) => {
      backend.loadAssetGroupList(response => {
        commit('setAssetGroupList', response.data)
        resolve()
      }, error => reject(error))
    }), 
    store: ({state, commit}) => new Promise((resolve, reject) => {

      let assetGroup = {...state.assetGroup}

      assetGroup.asset_types = assetGroup.asset_types.map(type => type.id)

      backend.store(assetGroup, response => {
        commit('addType', response.data)
        resolve(response.data)
      }, error => reject(error))
    }),
    save: ({state}) => new Promise((resolve, reject) => {

      let assetGroup = {...state.assetGroup}

      assetGroup.asset_types = assetGroup.asset_types.map(type => type.id)

      backend.saveAssetGroup(
        assetGroup,
        response => resolve(response.data),
        error => reject(error)
      )
    }), 
    deleteAssetGroup: ({state}) => new Promise((resolve, reject) => {
      backend.deleteAssetGroup(
        state.assetGroup.id,
        response => resolve(),
        error => reject(error)
      )
    }) 
  },

  getters: {
    assetGroups: state => state.assetGroups,
    assetGroupList: state => state.assetGroupList,
    assetGroup: state => state.assetGroup
  }

}
