import User from '@/models/User'
import ForgotPassword from '@/models/User/ForgotPassword'
import ResetPassword from '@/models/User/ResetPassword'
import ChangePassword from '@/models/User/ChangePassword'
import backend from '@/api/auth'
import { first, find } from 'lodash'
import momentTimezone from 'moment-timezone'
import { services } from '@/mixins/js-helpers'

let auth = {

  namespaced: true,

  state: {
    user: new User(),
    authenticated: false,
    forgotPassword: new ForgotPassword,
    resetPassword: new ResetPassword,
    changePassword: new ChangePassword,
    parked_token: null
  },

  mutations: {
    set: (state, payload) => state.user = payload,
    username: ({ user }, payload) => user.username = payload,
    fullName: ({ user }, payload) => user.full_name = payload,
    email: ({ user }, payload) => user.email = payload,
    password: ({ user }, payload) => user.password = payload,
    mobile: ({ user }, payload) => user.mobile = payload,
    dialingCode: (state, payload) => state.user.dialing_code = payload,
    company: ({ user }, payload) => user.company = payload,
    authenticated: (state) => state.authenticated = true,
    unauthenticated: (state) => state.authenticated = false,
    loggedIn: (state) => state.authenticated = true,
    updateTimeZone: (state, timezone) => state.user.timezone = timezone,
    setRegion: (state, payload) => state.user.region_id = payload,
    setIndustry: (state, payload) => state.user.industry_id = payload,

    // Forgot Password
    forgotPasswordEmail: (state, payload) => state.forgotPassword.email = payload,
    resetForgotPassword: state => state.forgotPassword = new ForgotPassword,

    // Reset Password
    resetPasswordEmail: (state, payload) => state.resetPassword.email = payload,
    resetPasswordPassword: (state, payload) => state.resetPassword.password = payload,
    resetPasswordPasswordConfirmation: (state, payload) => state.resetPassword.password_confirmation = payload,
    resetPasswordToken: (state, payload) => state.resetPassword.token = payload,
    resetResetPassword: state => state.resetPassword = new ResetPassword,

    // Change Password
    newPassword: (state, payload) => state.changePassword.password = payload,
    resetChangePassword: state => state.changePassword = new ChangePassword,
    clear: (state) => state.user = new User,
    parkToken: (state, token) => state.parked_token = token,
    twoFaActivated: (state) => state.user.two_factor_enabled = true,
    twoFaDisabled: (state) => state.user.two_factor_enabled = false,

    // Freshworks User Details
    setUser(state, user) {
      state.user.full_name = user.full_name;
      state.user.email = user.email;
      // Expose user data globally
      const event = new CustomEvent('userDataReady', {
        detail: {
          name: user.full_name,
          email: user.email,
        },
      });
      window.dispatchEvent(event);
    },
  },

  actions: {
    login: ({ state, dispatch }, payload) => new Promise((resolve, reject) => {
      backend.login(payload, ({ data }) => {
        if (data.two_factor) {
          return resolve(data)
        }
        services.auth.setToken(
          data.access_token
        ).then(() => {
          dispatch('loadUser').then(() => {
            resolve(data)
          }).catch(error => reject(error))
        })
      }, error => reject(error))
    }),
    signup: ({ state, dispatch }) => new Promise((resolve, reject) => {
      backend.signup(state.user, () => {
        dispatch('loadUser').then(() => {
          resolve()
        }).catch(reject)
      }, error => reject(error))
    }),
    logout: ({ commit }) => new Promise((resolve) => {
      services.auth.destroyToken()
      commit('unauthenticated')
      commit('clear')
      resolve()
    }),
    loadUser: ({ commit }) => new Promise((resolve, reject) => {
      backend.loadUser(({ data }) => {
        commit('set', data)
        commit('setUser', data);
        resolve(data)
      }, error => reject(error))
    }),

    // Passwords
    requestPasswordReset: ({ state, commit }) => new Promise((resolve, reject) => {
      backend.requestPasswordReset(state.forgotPassword, response => {
        commit('resetForgotPassword')
        resolve()
      }, reject)
    }),
    resetPassword: ({ state, commit }, token) => new Promise((resolve, reject) => {
      commit('resetPasswordToken', token)
      backend.resetPassword(state.resetPassword, () => {
        commit('resetResetPassword')
        resolve()
      }, reject)
    }),
    changePassword: ({ state, commit }) => new Promise((resolve, reject) => {
      backend.changePassword(state.changePassword, () => {
        commit('password', '')
        resolve()
      }, reject)
    }),
    firstPasswordChange: ({ state, commit }) => new Promise((resolve, reject) => {
      backend.firstPasswordChange(state.changePassword, () => {
        commit('password', '')
        resolve()
      }, reject)
    }),
    realignTimezone: ({ commit }, timezone) => new Promise((resolve, reject) => {
      backend.realignTimezone(timezone, () => {
        commit('updateTimeZone', timezone)
        resolve()
      }, reject)
    }),
    startTwoFa: ({}) => new Promise((resolve, reject) => {
      backend.enableTwoFa(resolve, reject)
    }),
    finalise2FaSetup: ({}, code) => new Promise((resolve, reject) => {
      backend.finalise2FaSetup(code, resolve, reject)
    }),
    twoFaChallenge: ({ state, dispatch}, payload) => new Promise((resolve, reject) => {
      backend.twoFaChallenge({
        ...payload,
        token: state.parked_token,
        email: state.user.username,
      }, () => {
        services.auth.setToken(
          state.parked_token
        ).then(({data}) => {
          dispatch('loadUser').then(() => {
            resolve(data)
          }).catch(error => reject(error))
        })
      }, reject)
    }),
  },

  getters: {
    user: state => state.user,
    abilities: state => state.user.abilities,
    authenticated: state => state.user.id,
    role: (state) => find(state.user.roles, role => role.name === 'admin') || first(state.user.roles),
    derivedTimezone: () => momentTimezone.tz.guess(),
    isOutOfTimeZone: (state, getters) => getters.derivedTimezone !== state.user.timezone,
    isAdmin: state => Boolean(
      find(state.user.roles, role => role.name === 'admin')
    ),
    isTechnician: state => Boolean(
      find(state.user.roles, role => role.name === 'technician')
    ),
    isSuper: state => Boolean(
      find(state.user.roles, role => role.name === 'super')
    ),
    isClient: state => Boolean(
      find(state.user.roles, role => role.name === 'client')
    ),
    mfaIsActive: state => state.user.two_factor_enabled,
    hasRole: (state) => (roleName) => {
      return state.user.roles.filter( role =>
          role.name === roleName).length > 0
    },
    hasAbility: (state) => (abilityName, modelPath) => {
      return state.user.abilities.filter(ability => ability.name === abilityName && ability.entity_type === modelPath).length > 0
    }
  }

}

export default auth
