import CustomField from '@/models/CustomField'
import CustomFieldTemplate from '@/models/CustomFieldTemplate'
import PredefinedAnswer from "@/models/PredefinedAnswer"
import {customField as backend} from '@/api'
import {common as commonBackend} from '@/api'

let customField = {

    namespaced: true,

    state: {
        customFields: {
            data: []
        },
        customFieldTemplates: {
            data: []
        },
        customField: new CustomField,
        customFieldsList: [],
        customFieldTemplate: new CustomFieldTemplate,
        customFieldTemplatesList: [],
        predefinedAnswer: new PredefinedAnswer(),
        predefinedAnswers: [],
        inputTypes: [
            {
                'name': 'Date',
                'input_type': 'date'
            },
            {
                'name': 'Date & Time',
                'input_type': 'datetime'
            },
            {
                'name': 'Text',
                'input_type': 'string'
            },
            {
                'name': 'Number',
                'input_type': 'integer'
            },
            {
                'name': 'Yes / No',
                'input_type': 'boolean'
            },
            {
                'name': 'Predefined List',
                'input_type': 'list'
            }
        ],
        templateTypes: [
            {
                'name': 'Site',
                'value': 'Billow\\Models\\Site'
            },
        ]
    },

    mutations: {
        setCustomField: (state, payload) => state.customField = payload,
        label: (state, payload) => state.customField.label = payload,
        placeholder: (state, payload) => state.customField.placeholder = payload,
        value: (state, payload) => state.customField.value = payload,
        required: (state, payload) => state.customField.required = payload,
        setCustomFieldTemplate: (state, payload) => state.customFieldTemplate = payload,
        setCustomFieldTemplates: (state, payload) => state.customFieldTemplates = payload,
        name: (state, payload) => state.customFieldTemplate.name = payload,
    },

    actions: {
        storeCustomField: ({}, payload) => new Promise(
            (resolve, reject) => {
                backend.storeCustomField(payload, ({data}) => {
                    resolve(data)
                }, error => reject(error))
            }
        ),
        storeCustomFieldTemplate: ({commit}, payload) => new Promise(
            (resolve, reject) => {
                backend.storeCustomFieldTemplate(payload, ({data}) => {
                    commit('setCustomFieldTemplate', data)
                    resolve(data)
                }, error => reject(error))
            }
        ),
        storePredefinedAnswer: ({}, payload) => new Promise(
            (resolve, reject) => {
                backend.storePredefinedAnswer(payload, ({data}) => {
                    resolve(data)
                }, error => reject(error))
            }
        ),
        assignCustomFieldTemplate: ({}, payload) => new Promise(
            (resolve, reject) => {
                backend.assignCustomFieldTemplate(payload, ({data}) => {
                    resolve(data)
                }, error => reject(error))
            }
        ),
        loadCustomFieldTemplates: ({commit}, path) => new Promise((resolve, reject) => {
                commonBackend.loadPath(path, ({data}) => {
                    commit('setCustomFieldTemplates', data)
                    resolve(data)
                }, error => reject(error))
            }
        ),
        loadCustomFieldTemplateList: ({commit}) => new Promise((resolve, reject) => {
                backend.loadCustomFieldTemplateList( ({data}) => {
                    commit('setCustomFieldTemplates', data)
                    resolve(data)
                }, error => reject(error))
            }
        ),
        loadCustomFieldTemplate: ({commit}, payload) => new Promise((resolve, reject) => {
                backend.loadCustomFieldTemplate(payload, ({data}) => {
                    commit('setCustomFieldTemplate', data)
                    resolve(data)
                }, error => reject(error))
            }
        ),
        loadCustomField: ({commit}, customFieldId) => new Promise((resolve, reject) => {
                backend.loadCustomField(customFieldId, ({data}) => {
                    commit('setCustomField', data)
                    resolve(data)
                }, error => reject(error))
            }
        ),
        updateCustomFieldTemplate: ({commit}, payload) => new Promise(
            (resolve, reject) => {
                backend.updateCustomFieldTemplate(payload, ({data}) => {
                    commit('setCustomFieldTemplate', data)
                    resolve(data)
                }, error => reject(error))
            }
        ),
        updatePredefinedAnswer: ({}, payload) => new Promise(
            (resolve, reject) => {
                backend.updatePredefinedAnswer(payload, ({data}) => {
                    resolve(data)
                }, error => reject(error))
            }
        ),
        updateCustomField: ({}, payload) => new Promise(
            (resolve, reject) => {
                backend.updateCustomField(payload, ({data}) => {
                    resolve(data)
                }, error => reject(error))
            }
        ),
        updateCustomFieldOrder: ({}, payload) => new Promise(
            (resolve, reject) => {
                backend.updateCustomFieldOrder(payload, ({data}) => {
                    resolve(data)
                }, error => reject(error))
            }
        ),
        assignTemplateToSites: ({}, payload) => new Promise(
            (resolve, reject) => {
                backend.assignTemplateToSites(payload, ({data}) => {
                    resolve(data)
                }, error => reject(error))
            }
        ),
        deletePredefinedAnswer: ({}, answerId) => new Promise(
            (resolve, reject) => {
                backend.deletePredefinedAnswer(answerId, ({data}) => {
                    resolve(data)
                }, error => reject(error))
            }
        ),
        deleteCustomField: ({}, customFieldId) => new Promise(
            (resolve, reject) => {
                backend.deleteCustomField(customFieldId, ({data}) => {
                    resolve(data)
                }, error => reject(error))
            }
        ),
        loadDeletedTemplates: ({ commit }) => new Promise(
            (resolve, reject) => {
                backend.loadDeletedTemplates(({data}) => {
                    commit('setCustomFieldTemplates', data)
                    resolve(data)
                }, error => reject(error))
            }
        ),
        restoreDeletedTemplate: ({}, customFieldTemplateId) => new Promise(
            (resolve, reject) => {
                backend.restoreDeletedTemplate(customFieldTemplateId, ({data}) => {
                    resolve(data)
                }, error => reject(error))
            }
        ),
        deleteCustomFieldTemplate: ({}, customFieldTemplateId) => new Promise(
            (resolve, reject) => {
                backend.deleteCustomFieldTemplate(customFieldTemplateId, ({data}) => {
                    resolve(data)
                }, error => reject(error))
            }
        ),
    },

    getters: {
        customField: state => state.customField,
        customFields: state => state.customFields,
        customFieldTemplate: state => state.customFieldTemplate,
        customFieldTemplates: state => state.customFieldTemplates,
        inputTypes: state => state.inputTypes,
        templateTypes: state => state.templateTypes
    }

}

export default customField
