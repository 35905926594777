import Site from '@/models/Site'
import Job from '@/models/Job'
import { site as backend } from '@/api'
import { common as commonBackend } from '@/api'
import findIndex from 'lodash/findIndex'

let site = {

  namespaced: true,

  state: {
    sites: {
      data: []
    },
    site: new Site,
    siteList: [],
    siteAdminsList: [],
    clientFilterSiteList: [],
    progressReportAssets: [],
    clientGroupedSiteList: [],
    clientUserSites: [],
    overview: {
      latest_job: new Job,
      passing_assets: [],
      failing_assets: [],
      total_open_jobs: 0,
      total_assets: 0
    },
    dashboard: {
      assets: {
        total: 0
      },
      users: {
        total: 0
      },
      client_users: {
        total: 0
      },
      zones: {
        total: 0
      },
      inspections: {
        total: 0,
        passed: 0,
        failed: 0,
        today: 0
      }
    },
    assetHealth: {},
    jobsPassPercentage: '',
    defectiveHighRiskAssets: []
  },

  mutations: {
    setSites: (state, payload) => state.sites = payload,
    setSiteAdminsList: (state, payload) => state.siteAdminsList = payload,
    setAssetHealth: (state, payload) => state.assetHealth = payload,
    setJobsPassPercentage: (state, payload) => state.jobsPassPercentage = payload,
    setDefectiveHighRiskAssets: (state, payload) => state.defectiveHighRiskAssets = payload,
    setSiteList: (state, payload) => state.siteList = payload,
    setClientFilterSiteList: (state, payload) => state.clientFilterSiteList = payload,
    setClientGroupedSiteList: (state, payload) => state.clientGroupedSiteList = payload,
    setClientUserSites: (state, payload) => state.clientUserSites = {...payload},
    setSite: (state, payload) => state.site = payload,
    setAddress: (state, payload) => state.site.address = payload,
    name: (state, payload) => state.site.name = payload,
    legalName: (state, payload) => state.site.legal_name = payload,
    registrationNumber: (state, payload) => state.site.registration_number = payload,
    vatNumber: (state, payload) => state.site.vat_number = payload,
    client: (state, payload) => state.site.client_id = payload,
    failureThreshold: (state, payload) => state.site.failure_threshold = payload,
    setProgressReportAssets: (state, payload) => state.progressReportAssets = payload,
    setOverview: (state, payload) => state.overview = payload,
    setDashboard: (state, payload) => state.dashboard = payload,
    users: (state, payload) => state.site.users = payload,
    siteManager: (state, payload) => state.site.site_manager_user_id = payload,
    phoneNumber: (state, payload) => state.site.phone_number = payload,
    dialingCode: (state, payload) => state.site.dialing_code = payload,
    clearManager: (state) => state.site.site_manager_user_id = '',
    toggleUser: (state, user) => {
      let index = findIndex(state.site.users, { id: user.id })
      if (index >= 0) {
        state.site.users.splice(index, 1)
        return
      }
      state.site.users.push(user)
    },
    clearUsers: (state) => state.site.users = [],
    updateLogoUrl: (state, payload) => {
      state.site.logo = payload
      state.site.logo_url = payload
    },
    clear: (state) => {
      state.sites = {
        data: []
      }
      state.site = new Site
      state.siteList = []
    },
  },

  actions: {
    loadSites: ({commit}, path) => new Promise((resolve, reject) => {
      commonBackend.loadPath(path, ({data}) => {
        commit('setSites', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadSiteList: ({commit}) => new Promise((resolve, reject) => {
      backend.loadSiteList(({data}) => {
        commit('setSiteList', data)
        resolve(data)
      }, error => reject(error))
    }),
    searchSite: ({ commit }, search) => new Promise((resolve, reject) => {
      backend.searchSite(search, ({ data }) => {
        commit('setSiteList', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadSiteListGroupedByClient: ({ commit }, query) => new Promise((resolve, reject) => {
      backend.loadSiteListGroupedByClient(query, response => {
        commit('setClientGroupedSiteList', response.data)
        resolve()
      }, reject)
    }),
    loadClientSiteList: ({commit}, clientId) => new Promise((resolve, reject) => {
      backend.loadClientSiteList(clientId, ({data}) => {
        commit('setSiteList', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadClientUserSites: ({ commit }, {clientId, query} ) => new Promise((resolve, reject) => {
      backend.loadClientUserSites(clientId, query, response => {
        commit('setClientUserSites', response.data)
        resolve(response.data)
      }, error => reject(error))
    }),
    loadClientFilterSiteList: ({ commit }, clientId ) => new Promise((resolve, reject) => {
      backend.loadClientFilterSiteList(clientId, ({data}) => {
        commit('setClientFilterSiteList', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadSiteListByCompany: ({commit}) => new Promise((resolve, reject) => {
      backend.loadSiteListByCompany(({data}) => {
        commit('setSiteList', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadProgressReportAssets: ({commit}, siteId) => new Promise((resolve, reject) => {
      backend.loadProgressReportAssets(siteId, ({data}) => {
        commit('setProgressReportAssets', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadSitesByClient: ({commit}, path) => new Promise((resolve, reject) => {
      backend.loadSitesByClient(path, ({data}) => {
        commit('setSites', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadSite: ({commit}, siteId) => new Promise((resolve, reject) => {
      backend.loadSite(siteId, ({data}) => {
        commit('setSite', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadSiteDataForOverview: ({commit}, siteId) => new Promise((resolve, reject) => {
      backend.loadSiteDataForOverview(siteId, ({data}) => {
        commit('setSite', data)
        resolve(data)
      }, error => reject(error))
    }),
    fetchTechnicians: (context, siteId) => new Promise((resolve, reject) => {
      backend.fetchTechnicians(siteId, ({data}) => {
        resolve(data)
      }, error => reject(error))
    }),
    fetchSiteAdmins: ({commit}, siteId) => new Promise((resolve, reject) => {
      backend.fetchSiteAdmins(siteId, ({data}) => {
        commit('setSiteAdminsList', data)
        resolve(data)
      }, error => reject(error))
    }),
    create: ({commit, state}) => new Promise((resolve, reject) => {
      backend.createSite(state.site.payload, ({data}) => {
        commit('setSite', data)
        resolve(data)
      }, error => reject(error))
    }),
    save: ({state}) => new Promise((resolve, reject) => {
      backend.saveSite({
        ...state.site,
        users: state.site.users.map(user => user.id)
      }, ({data}) => {
        resolve(data)
      }, error => reject(error))
    }),
    syncUsers: ({ state }) => new Promise((resolve, reject) => {
      backend.syncUsers(state.site.id, {
        users: state.site.users.map(site => site.id)
      }, resolve, reject)
    }),
    deleteSite: ({state}) => new Promise((resolve, reject) => {
      backend.deleteSite(state.site.id, ({data}) => {
        resolve(data)
      }, error => reject(error))
    }),
    uploadLogo: ({state, commit}, file) => new Promise((resolve, reject) => {
      backend.uploadLogo({
        site: state.site.id,
        file
      }, ({data}) => {
        commit('updateLogoUrl', data)
        resolve(data)
      }, error => reject(error))
    }),
    resetSiteLogo: ({state, commit}) => new Promise((resolve, reject) => {
      backend.resetSiteLogo({
        site: state.site.id,
      }, ({ data }) => {
        commit('updateLogoUrl', data)
        resolve(data)
      }, error => reject(error))
    }),    
    loadOverview: ({ commit }, siteId) => new Promise((resolve, reject) => backend.loadOverview(
      siteId,
      ({ data }) => {
        commit('setOverview', data)
        resolve(data)
      },
      error => reject(error)
    )),
    loadDefectiveHighRiskAssets: ({ commit }, siteId) => new Promise((resolve, reject) => backend.loadDefectiveHighRiskAssets(
      siteId,
      ({ data }) => {
        commit('setDefectiveHighRiskAssets', data)
        resolve(data)
      },
      error => reject(error)
    )),
    loadAssetHealth: ({ commit }, siteId) => new Promise((resolve, reject) => backend.loadAssetHealth(
      siteId,
      ({ data }) => {
        commit('setAssetHealth', data)
        resolve(data)
      },
      error => reject(error)
    )),
    recoverSite: ({}, siteId) => new Promise((resolve, reject) => backend.recoverSite(
      siteId, resolve, reject
    )),
    mailFailedChecksActivityReport: ({}, siteId) => new Promise((resolve, reject) => backend.mailFailedChecksActivityReport(
      siteId, resolve, reject
    )),
  },

  getters: {
    sites: state => state.sites,
    siteList: state => state.siteList,
    siteAdminsList: state => state.siteAdminsList,
    clientFilterSiteList: state => state.clientFilterSiteList,
    site: state => state.site,
    progressReportAssets: state => state.progressReportAssets,
    overview: state => state.overview,
    dashboard: state => state.dashboard,
    assetHealth: state => state.assetHealth,
    jobsPassPercentage: state => state.jobsPassPercentage,
    defectiveHighRiskAssets: state => state.defectiveHighRiskAssets,
  }

}

export default site
