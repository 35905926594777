import * as api from '@/api/support'
import { common as commonApi } from '@/api'
import SupportTicket from '@/models/SupportTicket'
import Paginator from '@/models/Paginator'
import Comment from '@/models/comment'
import File from '@/models/File'

let state = {
  tickets: new Paginator,
  ticket: new SupportTicket,
  newTicket: new SupportTicket,
  comment: new Comment,
  file: new File,
}

let mutations = {
  // Tickets
  setTickets: (state, payload) => state.tickets = payload,

  // Ticket
  clearTicket: state => state.ticket = new SupportTicket,
  setTicket: (state, payload) => state.ticket = payload,
  addFile: (state, payload) => state.ticket.files.push(payload),

  // New Ticket
  clearNewTicket: state => state.newTicket = new SupportTicket,
  setTitle: (state, payload) => state.newTicket.title = payload,
  setBody: (state, payload) => state.newTicket.body = payload,
  setPriority: (state, payload) => state.newTicket.priority = payload,
  setType: (state, payload) => state.newTicket.type = payload,
  setFile: (state, payload) => state.newTicket.file = payload,

  // Comment
  clearComment: state => state.comment = new Comment,
  setCommentText: (state, payload) => state.comment.text = payload,

  // File
  clearFile: state => state.file = new File,
  setNewFile: (state, payload) => state.file.file = payload,
}

let actions = {
  loadTickets: ({ commit }, path) => new Promise((resolve, reject) => {
    commonApi.loadPath(path, response => {
      commit('setTickets', response.data)
      resolve()
    }, reject)
  }),

  openTicket: ({ state }) => new Promise((resolve, reject) => {
    api.openTicket(state.newTicket.payload, response => {
      resolve(response.data)
    }, reject)
  }),

  loadTicket: ({ commit }, ticket) => new Promise((resolve, reject) => {
    api.loadTicket(ticket, response => {
      commit('setTicket', response.data)
      resolve()
    }, reject)
  }),

  addComment: ({ state, commit }) => new Promise((resolve, reject) => {
    api.addComment({
      ticket: state.ticket.reference,
      comment: state.comment
    }, response => {
      commit('clearComment')
      resolve(response.data)
    }, reject)
  }),

  attachFile: ({ state, commit }) => new Promise((resolve, reject) => {
    api.attachFile({
      ticket: state.ticket.reference,
      file: state.file
    }, response => {
      commit('clearFile')
      commit('addFile', response.data)
      resolve(response.data)
    }, reject)
  }),

  resolveTicket: ({ state }) => new Promise((resolve, reject) => {
    api.resolveTicket(state.ticket.reference, resolve, reject)
  }),

  closeTicket: ({ state }) => new Promise((resolve, reject) => {
    api.closeTicket(state.ticket.reference, resolve, reject)
  }),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
