export default {

  namespaced: true,
  
  state: {
    needsUpdate: false
  },

  mutations: {
    appNeedsUpdate: (state) => state.needsUpdate = true,
    clearUpdater: (state) => state.needsUpdate = false,
  },

  getters: {
    appNeedsUpdate: state => state.needsUpdate
  }

}