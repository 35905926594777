import { schedule as backend } from '@/api' 

let role = {
  
  namespaced: true,

  state: {
    schedule: []
  },

  mutations: {
    setSchedule: (state, payload) => state.schedule = payload
  },

  actions: {
    loadSchedule: ({commit}) => new Promise((resolve, reject) => {
      backend.loadSchedule(response => {
        commit('setSchedule', response.data)
        resolve(response.data)
      }, error => reject(error))
    })
  },

  getters: {
    schedule: state => state.schedule
  }

}

export default role