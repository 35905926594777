import { recurringJob as backend } from '@/api'
import Paginator from '@/models/Paginator'
import RecurringJob from '@/models/RecurringJob'
import ScheduledJob from '@/models/ScheduledJob'

let recurring = {

  namespaced: true,

  state: {
    recurringJob: new RecurringJob,
    scheduledJob: new ScheduledJob,
    jobs: new Paginator,
    schedule: new Paginator,

    frequencyExclusionsMapped: false,

    frequencies: [
      { value: 'daily', label: 'Daily' },
      { value: 'weekly', label: 'Weekly' },
      { value: 'every-2-weeks', label: 'Every 2 Weeks' },
      { value: 'monthly', label: 'Monthly' },
      { value: 'quarterly', label: 'Quarterly' },
      { value: 'biannually', label: 'Biannually' },
      { value: 'annually', label: 'Annually' },
    ],
  },

  mutations: {
    // Recurring Job
    setRecurringJob: (state, payload) => {
      state.recurringJob = (new RecurringJob).hydrate(payload)
      state.recurringJob.zones = state.recurringJob.zones.map(zone => zone.id)
    },
    clear: (state) => state.recurringJob = new RecurringJob,
    frequencyExclusionsMapped: (state, mapped = true) => state.frequencyExclusionsMapped = mapped,

    setScheduledJob: (state, payload) => state.scheduledJob = (new ScheduledJob).hydrate(payload),

    toggleActive: (state) => state.recurringJob.active = !state.recurringJob.active,
    setReference: (state, payload) => state.recurringJob.reference = payload,
    setTechnician: (state, payload) => state.recurringJob.technician_id = payload,
    setStartDate: (state, payload) => state.recurringJob.start_date = payload,
    setEndDate: (state, payload) => state.recurringJob.end_date = payload,
    setFrequency: (state, payload) => state.recurringJob.frequency = payload,
    setEarlyClosure: (state, payload) => state.recurringJob.allow_early_closure = payload,
    setJobType: (state, payload) => state.recurringJob.job_type_id = payload,
    setFrequencyInterval: (state, payload) => state.recurringJob.frequency.interval = payload,
    setFrequencyExclusions: (state, payload) => state.recurringJob.frequency.exclusions = payload,
    setFrequencyExclusionRule: (state, payload) => state.recurringJob.frequency.exclusion_rule = payload,
    setSite: (state, payload) => state.recurringJob.site_id = payload,
    setSpecialInstructions: (state, payload) => state.recurringJob.special_instructions = payload,
    setZones: (state, payload) => state.recurringJob.zones = payload,
    openFirstJobImmediately: (state, payload) => state.recurringJob.open_first_job_immediately = payload,
    reference: (state, payload) => state.recurringJob.reference = payload,

    // Jobs
    setJobs: (state, payload) => state.jobs = payload,
    clearJobs: (state) => state.jobs = new Paginator,

    // Schedule
    setSchedule: (state, payload) => state.schedule = payload,
    clearSchedule: (state) => state.schedule = new Paginator,
  },

  actions: {
    loadRecurringJob: ({ commit }, recurringJobId) => new Promise((resolve, reject) => {
      backend.loadRecurringJob(recurringJobId, response => {
        commit('frequencyExclusionsMapped', false)
        commit('setRecurringJob', response.data)
        resolve()
      }, reject)
    }),

    loadScheduledJob: ({ commit }, payload) => new Promise((resolve, reject) => {
      backend.loadScheduledJob(payload, response => {
        commit('setScheduledJob', response.data)
        resolve()
      }, reject)
    }),

    paginatedJobs: ({ state, commit }, path = null) => new Promise((resolve, reject) => {
      backend.paginatedJobs(path || state.recurringJob.id, response => {
        commit('setJobs', response.data)
        resolve()
      }, reject)
    }),

    paginatedSchedule: ({ state, commit }, path = null) => new Promise((resolve, reject) => {
      backend.paginatedSchedule(path || state.recurringJob.id, response => {
        commit('setSchedule', response.data)
        resolve()
      }, reject)
    }),

    start: ({ state, commit }) => new Promise((resolve, reject) => {
      commit('toggleActive')
      backend.start(state.recurringJob.id, resolve, reject)
    }),

    pause: ({ state, commit }) => new Promise((resolve, reject) => {
      commit('toggleActive')
      backend.pause(state.recurringJob.id, resolve, reject)
    }),

    preview: ({ state }) => new Promise((resolve, reject) => {
      backend.previewRecurring(state.recurringJob.payload, resolve, reject)
    }),

    previewUpdate: ({ state }) => new Promise((resolve, reject) => {
      backend.previewRecurringUpdate(state.recurringJob.payload, resolve, reject)
    }),

    store: ({ state }) => new Promise((resolve, reject) => {
      backend.storeRecurring(state.recurringJob.payload, resolve, reject)
    }),

    save: ({ state }) => new Promise((resolve, reject) => {
      backend.save(state.recurringJob.payload, resolve, reject)
    }),

    deleteRecurring: ({ }, job) => new Promise((resolve, reject) => {
      backend.deleteRecurring(job, resolve, reject)
    }),

    overrideScheduledJobTechnician: ({ }, payload) => new Promise((resolve, reject) => {
      backend.overrideScheduledJobTechnician(
        payload,
        resolve,
        reject
      )
    }),

    resetScheduledJobTechnician: ({ state }, scheduledJob) => new Promise((resolve, reject) => {
      backend.resetScheduledJobTechnician(
        state.recurringJob.id,
        scheduledJob,
        resolve,
        reject
      )
    }),

    changeScheduledJobDate: ({ state }, payload) => new Promise((resolve, reject) => {
      backend.changeScheduledJobDate(
        state.recurringJob.id,
        payload,
        resolve,
        reject
      )
    }),

    deleteRecurring: ({ state }, payload) => new Promise((resolve, reject) => {
      backend.deleteRecurring(
        state.recurringJob.id,
        payload,
        resolve,
        reject
      )
    }),

    reopenSkippedScheduledJob: ({ state }) => new Promise((resolve, reject) => {
      backend.reopenSkippedScheduledJob(
        state.recurringJob.id,
        resolve,
        reject
      )
    }),

    deleteScheduledJob: ({ state }, scheduledJob) => new Promise((resolve, reject) => {
      backend.deleteScheduledJob(
        state.recurringJob.id,
        scheduledJob,
        resolve,
        reject
      )
    })
  },

  getters: {
    recurringJob: state => state.recurringJob,
  }

}

export default recurring
