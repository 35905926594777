import { ability as backend } from '@/api'

export default {

  namespaced: true,

  state: {
    abilities: []
  },

  mutations: {
    setAbilities: (state, abilities) => state.abilities = abilities
  },

  actions: {
    loadAbilities: ({commit}) => new Promise((resolve, reject) => {
      backend.loadAbilities(({data}) => {
        commit('setAbilities', data)
        resolve()
      }, reject)
    }),
    allowAbility: ({}, payload) => new Promise((resolve, reject) => {
      backend.allowAbility(payload, ({data}) => {
        resolve()
      }, reject)
    }),
    removeAbility: ({}, payload) => new Promise((resolve, reject) => {
      backend.removeAbility(payload, ({data}) => {
        resolve()
      }, reject)
    }),
    allowAllAbilities: ({}, payload) => new Promise((resolve, reject) => {
      backend.allowAllAbilities(payload, ({data}) => {
        resolve()
      }, reject)
    }),
    removeAllAbilities: ({}, payload) => new Promise((resolve, reject) => {
      backend.removeAllAbilities(payload, ({data}) => {
        resolve()
      }, reject)
    })
  },

  getters: {
    abilities: state => state.abilities
  }

}