import { dashboard as backend } from '@/api'
import Banner from "@/models/Banner";

let dashboard = {

  namespaced: true,

  state: {
    dashboard: {
      recent_inspections: [],
      total_open_jobs: '',
      counts: {
        assets: {
          total: '',
          today: '',
          defective: '',
        },
        inspections: {
          total: '',
          today: '',
        },
        clients: {
          total: '',
        },
        risk: {},
        technicians: {
          total: '',
        },
        sites: {
          total: '',
          passing: '',
          failing: '',
        }
      },
      banner: new Banner()
    },
    pendingSignatures: '',
    assetDashboardCounts: [],
    assetDashboardLists: [],
    assetDashboardGraphs: [],
    assetDashboardInspections: [],
  },

  mutations: {
    setDashboard: (state, payload) => state.dashboard = payload,
    setPendingSignatures: (state, payload) => state.pendingSignatures = payload,
    setAssetDashboardCounts: (state, payload) => state.assetDashboardCounts = payload,
    setAssetDashboardLists: (state, payload) => state.assetDashboardLists = payload,
    setAssetDashboardGraphs: (state, payload) => state.assetDashboardGraphs = payload,
    setAssetDashboardInspections: (state, payload) => state.assetDashboardInspections = payload,
  },

  actions: {
    loadAdmin: ({commit}) => new Promise((resolve, reject) => {
      backend.loadAdmin(response => {
        commit('setDashboard', response.data)
        resolve(response.data)
      }, error => reject(error))
    }),
    loadPendingSignatures: ({commit}) => new Promise((resolve, reject) => {
      backend.loadPendingSignatures(response => {
        commit('setPendingSignatures', response.data)
        resolve(response.data)
      }, error => reject(error))
    }),
    loadAssetDashboardCounts: ({commit}) => new Promise((resolve, reject) => {
      backend.loadAssetDashboardCounts(response => {
        commit('setAssetDashboardCounts', response.data)
        resolve(response.data)
      }, error => reject(error))
    }),
    loadAssetDashboardLists: ({commit}) => new Promise((resolve, reject) => {
      backend.loadAssetDashboardLists(response => {
        commit('setAssetDashboardLists', response.data)
        resolve(response.data)
      }, error => reject(error))
    }),
    loadAssetDashboardGraphs: ({commit}) => new Promise((resolve, reject) => {
      backend.loadAssetDashboardGraphs(response => {
        commit('setAssetDashboardGraphs', response.data)
        resolve(response.data)
      }, error => reject(error))
    }),
    loadAssetDashboardInspections: ({commit}) => new Promise((resolve, reject) => {
      backend.loadAssetDashboardInspections(response => {
        commit('setAssetDashboardInspections', response.data)
        resolve(response.data)
      }, error => reject(error))
    }),
  },

  getters: {
    dashboard: state => state.dashboard,
    pendingSignatures: state => state.pendingSignatures,
    assetDashboardCounts: state => state.assetDashboardCounts,
    assetDashboardLists: state => state.assetDashboardLists,
    assetDashboardGraphs: state => state.assetDashboardGraphs,
    assetDashboardInspections: state => state.assetDashboardInspections,
  }

}

export default dashboard
