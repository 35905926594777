import Check from "@/models/Check"
import { check as backend } from '@/api'
import { common as commonBackend } from '@/api'

let check = {

  namespaced: true,

  state: {
    checks: {
      data: []
    },
    checkList: [],
    check: new Check,
    checklistChecks: []
  },

  mutations: {
    setChecks: (state, payload) => state.checks = payload,
    setChecksList: (state, payload) => state.checkList = payload,
    setChecklistChecks: (state, payload) => state.checklistChecks = payload,
    setCheck: (state, payload) => state.check = payload,
    addCheck: (state, payload) => state.checks.data.push(payload),
    name: (state, payload) => state.check.name = payload,
    task: (state, payload) => state.check.task = payload,
    imageRule: (state, payload) => state.check.image_rule = payload,
    commentRule: (state, payload) => state.check.comment_rule = payload,
    image: (state, payload) => state.check.image = payload,
    instantFail: (state) => state.check.instant_fail = !state.check.instant_fail,
    canSkip: (state) => state.check.can_skip = !state.check.can_skip,
    clearCheck: (state) => state.check = new Check,
    clear: (state) => {
      state.checks = {
        data: []
      }
      state.checkList = [],
      state.check = new Check
    },
  },

  actions: {
    loadChecks: ({commit}, payload) => new Promise((resolve, reject) => {
      commonBackend.loadPath(payload, response => {
        commit('setChecks', response.data)
        resolve()
      }, error => reject(error))
    }),
    loadChecksList: ({commit}) => new Promise((resolve, reject) => {
      backend.loadChecks('/api/checks/list', response => {
        commit('setChecksList', response.data)
        resolve()
      }, error => reject(error))
    }),
    loadCheck: ({state, commit}) => new Promise((resolve, reject) => {
      backend.loadCheck(state.check.id, response => {
        commit('setCheck', response.data)
        resolve()
      }, error => reject(error))
    }),
    searchChecksList: ({commit}, payload) => new Promise((resolve, reject) => {
      backend.search(`/api/checks/search`, payload, response => {
        commit('setChecksList', response.data)
        resolve(response.data)
      }, error => reject(error))
    }),
    store: ({state}) => new Promise((resolve, reject) => {
      backend.store(state.check, response => {
        resolve(response.data)
      }, error => reject(error))
    }),
    update: ({ state, commit }) => new Promise((resolve, reject) => {
      backend.update(state.check, ({ data }) => {
        commit('clearCheck')
        resolve(data)
      }, error => reject(error))
    }),
    delete: ({ state, commit }) => new Promise((resolve, reject) => {
      backend.delete(state.check, () => {
        commit('clearCheck')
        resolve()
      }, error => reject(error))
    }),
    getChecksFromChecklist: ({ commit }, checklistId) => new Promise((resolve, reject) => {
        backend.getChecksFromChecklist(checklistId, response => {
          commit('setChecklistChecks', response.data);
          resolve(response.data);
        }, error => reject(error));
    })
  },

  getters: {
    checks: state => state.checks,
    check: state => state.check,
    checkList: state => state.checkList,
    checklistChecks: state => state.checklistChecks
  }

}

export default check
