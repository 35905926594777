import { jobType as backend } from '@/api'
import { common as commonBackend } from '@/api'
import JobType from '@/models/JobType'
import Paginator from '@/models/Paginator'
import {findIndex} from "lodash";

let jobType = {

  namespaced: true,

  state: {
    jobTypes: new Paginator,
    jobTypeList: [],
    jobType: new JobType
  },

  mutations: {
    setJobTypes: (state, payload) => state.jobTypes = payload,
    setJobType: (state, payload) => state.jobType = payload,
    setJobTypeList: (state, payload) => state.jobTypeList = payload,
    description: (state, payload) => state.jobType.description = payload,
    passPercentage: (state, payload) => state.jobType.pass_percentage = payload,
    toggleActive: (state) => state.jobType.active = !state.jobType.active,
    toggleSingleInspection: (state) => state.jobType.single_inspection = !state.jobType.single_inspection,
    toggleSignatureRequirement: (state) => state.jobType.requires_signature = !state.jobType.requires_signature,
    toggleMapOnReport: (state) => state.jobType.report_with_map = !state.jobType.report_with_map,
    toggleAssetMetaFieldsOnInspectionReport: (state) => state.jobType.report_with_asset_meta = !state.jobType.report_with_asset_meta,
    toggleAssetMetaFieldsOnJobReport: (state) => state.jobType.job_report_with_asset_meta = !state.jobType.job_report_with_asset_meta,
    toggleTechnicianSignatureRequirement: (state) => state.jobType.requires_technician_signature = !state.jobType.requires_technician_signature,
    pushJobType: (state, payload) => {
      state.jobTypes.data.push(payload)
      state.jobTypeList.push(payload)
    },
    clear(state) {
      state.jobTypes = new Paginator
      state.jobTypeList = []
      state.jobType = new JobType
    },
    clearJobType(state) {
      state.jobType = new JobType
    },
    addClosingCustomField: (state, field) => state.jobType.closing_custom_fields.push(field),
    updateFieldLabel: (state, payload) => {
      let index = findIndex(state.jobType.closing_custom_fields, field => field.field_id === payload.fieldId)
      let field = state.jobType.closing_custom_fields[index]
      field.label = payload.value
      state.jobType.closing_custom_fields.splice(index, 1, field)
    },
    updateFieldPlaceholder: (state, payload) => {
      let index = findIndex(state.jobType.closing_custom_fields, field => field.field_id === payload.fieldId)
      let field = state.jobType.closing_custom_fields[index]
      field.placeholder = payload.value
      state.jobType.closing_custom_fields.splice(index, 1, field)
    },
    toggleRequiredField: (state, fieldId) => {
      let index = findIndex(state.jobType.closing_custom_fields, field => field.field_id === fieldId)
      let field = state.jobType.closing_custom_fields[index]
      field.is_required = !field.is_required
      state.jobType.closing_custom_fields.splice(index, 1, field)
    },
    deleteField: (state, field) => {
      let index = findIndex(state.jobType.closing_custom_fields, customField => customField.field_id === field.field_id)
      state.jobType.closing_custom_fields.splice(index, 1)
    },
  },

  actions: {
    loadJobTypes: ({ commit }, path) => new Promise((resolve, reject) => {
      commonBackend.loadPath(path, response => {
        commit('setJobTypes', response.data)
        resolve()
      }, error => reject(error))
    }),
    loadJobType: ({ commit }, jobTypeId) => new Promise((resolve, reject) => {
      backend.loadJobType(jobTypeId, response => {
        commit('setJobType', response.data)
        resolve()
      }, error => reject(error))
    }),
    loadJobTypeList: ({commit}) => new Promise((resolve, reject) => {
      backend.loadJobTypeList(response => {
        commit('setJobTypeList', response.data)
        resolve(response.data)
      }, error => reject(error))
    }),
    searchJobTypes: ({ commit }, search) => new Promise((resolve, reject) => {
      backend.searchJobTypes(search, ({ data }) => {
        commit('setJobTypeList', data)
        resolve(data)
      }, error => reject(error))
    }),
    searchActiveJobTypes: ({ commit }, search) => new Promise((resolve, reject) => {
      backend.searchActiveJobTypes(search, ({ data }) => {
        commit('setJobTypeList', data)
        resolve(data)
      }, error => reject(error))
    }),
    store: ({state, commit}) => new Promise((resolve, reject) => {
      backend.storeJobType(state.jobType, response => {
        commit('pushJobType', response.data)
        resolve(response.data)
      }, error => reject(error))
    }),
    save: ({state}) => new Promise((resolve, reject) => {
      backend.saveJobType(state.jobType, response => {
        resolve(response.data)
      }, error => reject(error))
    }),
    delete: ({state}) => new Promise((resolve, reject) => {
      backend.delete(state.jobType.id, response => {
        resolve(response.data)
      }, error => reject(error))
    }),
    recoverDeletedJobType: ({}, jobTypeId) => new Promise((resolve, reject) => {
      backend.recoverDeletedJobType(jobTypeId, response => {
        resolve(response.data)
      }, error => reject(error))
    })
  },

  getters: {
    jobTypes: state => state.jobTypes,
    jobTypeList: state => state.jobTypeList,
    jobType: state => state.jobType
  }

}

export default jobType
