import { risk as backend } from '@/api'

let riskLevel = {

  namespaced: true,

  state: {
    riskLevels: [],
    riskLevelList: []
  },

  mutations: {
    setRiskLevels(state, payload) {
      state.riskLevels = payload
    }, 
    setRiskLevelList(state, payload) {
      state.riskLevelList = payload
    } 
  },

  actions: {
    loadRiskLevels: ({commit}) => new Promise((resolve, reject) => {
      backend.loadRiskLevels(response => {
        commit('setRiskLevels', response.data)
        resolve(response.data)
      }, error => reject(error))
    }),
    loadRiskLevelList: ({commit}) => new Promise((resolve, reject) => {
      backend.loadRiskLevelList(response => {
        commit('setRiskLevelList', response.data)
        resolve(response.data)
      }, error => reject(error))
    })
  },

  getters: {
    riskLevels: state => state.riskLevels,
    riskLevelList: state => state.riskLevelList,
  }

}

export default riskLevel