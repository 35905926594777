import { imports as backend } from '@/api'

let imports = {

  namespaced: true,

  state: {
    import: {},
    importItems: [],
  },

  mutations: {
    setImport: (state, payload) => state.import = payload,
    setImportItems: (state, payload) => state.importItems = payload,
  },

  actions: {
    loadImport: ({ commit }, importId) => new Promise((resolve, reject) => {
      backend.loadImport(importId, response => {
        commit('setImport', response.data)
        commit('setImportItems', response.data.import_items)
        resolve()
      }, reject)
    }),

    importAssets: ({ commit }, payload) => new Promise((resolve, reject) => {
      backend.importAssets(
        payload,
        resolve,
        reject,
      )
    }),

    importUsers: ({}, file) => new Promise((resolve, reject) => {
      backend.importUsers(file, resolve, reject)
    })
  },

  getters: {
    import: state => state.import,
    importItems: state => state.importItems,
  },
}

export default imports
