<template>
  <transition
    enter-active-class="expand-collapse-enter-active"
    enter-to-class="expand-collapse-enter-to"
    leave-active-class="expand-collapse-leave-active"
    leave-to-class="expand-collapse-leave-to"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @leave="leave"
    @after-leave="afterLeave">
    <slot />
  </transition>
</template>

<script>
export default {
  methods: {
    beforeEnter(element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.height = '0px';
        }
        element.style.display = null;
      });
    },

    enter(element) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          element.style.height = `${element.scrollHeight}px`;
        });
      });
    },

    afterEnter(element) {
      element.style.height = null;
    },

    beforeLeave(element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.height = `${element.clientHeight}px`;
        }
      });
    },

    leave(element) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          element.style.height = '0px';
        });
      });
    },

    afterLeave(element) {
      element.style.height = null;
    },
  },
}
</script>

<style scoped>
* {
  will-change: height;
  /* transform: translateZ(0); */
  backface-visibility: hidden;
  /* perspective: 1000px; */
}
</style>
